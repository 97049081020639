<template>
  <div class="row">
    <div class="col">
      <card-container
              :title="$router.currentRoute.meta.title"
              :headers="tableHeaders"
              :lastPage="lastPage"
              :totalElements="totalElements"
              :currentPage="getCurrentPage()"
              :clickHandler="pagination">

        <template v-slot:card-header/>

        <template v-slot:card-header-search>
          <search v-model="lastPage" :listHandler="getList" style="margin-top: 30px"/>
        </template>

        <template v-slot:table-rows>
          <tr v-for="(item,index) in items" :key="item.id">
            <td> {{ item.id }} </td>
            <td> {{ item.allocationWindow.captainId  }} </td>
            <td> {{ $t(`${transPrefix}.lists.payment_type.${item.paymentType}`) }} </td>
            <td> {{ $t(`${transPrefix}.lists.status.${item.status}`) }} </td>
            <td class="text-direction-ltr"> {{ item.completedAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
            <td class="text-direction-ltr"> {{ item.createdAt | moment("Y-MM-DD hh:mm:ss A")  }} </td>
          </tr>
          <tr class="text-center" v-if="!items.length">
            <td colspan="999">{{ $t('messages.no_result_found')}}</td>
          </tr>
        </template>

        <template v-slot:card-footer>
          <export-action :crudCode="exportCode" v-can:report="permissionPrefix" :portalType="`suppliers`"></export-action>
        </template>
      </card-container>
    </div>
  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import { ORDERS_LIST as CRUD_ACTION_LIST } from 'actions/suppliers_portal/orders';
  import search from "./partials/search";
  const ORDERS_CODE="orders";
  const CRUD_CODE=`suppliers_portal.${ORDERS_CODE}`;
  const CRUD_PERMISSION_CODE=`supplier_${ORDERS_CODE}`;
  export default {
    name: "SuppliersPortalOrderIndex",
    components: {
      search
    },
    data(){

      return {
        routePrefix: CRUD_CODE,
        transPrefix: ORDERS_CODE,
        permissionPrefix: CRUD_PERMISSION_CODE,
        exportCode:ORDERS_CODE,
        tableHeaders:[
          {
            'title':`fields.id`
          },
          {
            'title' : `fields.captain_id`
          },
          {
            'title':`${ORDERS_CODE}.fields.payment_type`
          },
          {
            'title':`fields.status`
          },
          {
            'title':`fields.completed_at`
          },
          {
            'title':`fields.created_at`
          },
        ],
        items: [],
        perPage:20,
        totalElements:0,
        lastPage:0
      }
    },
    mounted() {
      this.getList();
    },
    computed:{
      ...mapGetters([
        "getSearchFilters","getSearchFiltersIfExists","getSearchFiltersSensitized"
      ])
    },
    methods: {
      getList(){
        this.$store.dispatch(CRUD_ACTION_LIST,{
          pageId: this.getCurrentPage()-1,
          ...this.getSearchFiltersSensitized
        }).then(response=>{
          this.items=response.data.data;
          this.perPage=response.data.perPage;
          this.totalElements=response.data.totalElements;
          this.lastPage=response.data.pageCount;
        });
      },
      pagination(page){
        if(page!== this.getCurrentPage()){
          this.$router.push({
            name: `${this.routePrefix}.index`,
            query:{
              page, 
              ...this.getSearchFiltersIfExists
            }
          });
          this.getList();
        }
      },
      getCurrentPage(){
        let currentPage=this.$route.query.page??1;
        return parseInt(currentPage>=1?currentPage:1);
      },
    }
  };
</script>
