<template>
    <td>
        <template v-for="action in actions">
            <table-item-actions-delete v-if="action.code==='delete'" :options="action" :params="params" />
            <table-item-actions-show v-else-if="action.code==='show'" :options="action" :params="params" :query="query"/>
            <table-item-actions-edit v-else-if="action.code==='edit'" :options="action" :params="params" :query="query"/>
            <router-link v-else-if="action.isPopUp" :to="{ name:action.route_name,params:{...params},query:{...query}}" class="px-1" v-b-tooltip.hover="{ animation: false}" :title="$t(action.title?action.title:'')" v-bind:class="[action.color_class]" v-can="action.can">
                <i class="" v-bind:class="[action.icon]"></i>
            </router-link>
            <router-link v-else :to="{ name:action.route_name,params:{...params},query:{...query}}" class="px-1" v-b-tooltip.hover="{ animation: false}" :title="$t(action.title?action.title:'')" v-bind:class="[action.color_class]" v-can="action.can">
                <i class="" v-bind:class="[action.icon]"></i>
            </router-link>
        </template>
        <slot name="additional-items"></slot>
    </td>
</template>


<script>
    export default {
        props: {
            actions: {
                type: Array
            },
            params:{
                type:Object,
                default:function(){
                    return {};
                }
            },
            query:{
                type:Object,
                default:function(){
                    return {};
                }
            },
        },
        name: "TableItemActions",
        data(){
            return {

            }
        },
    }
</script>

<style scoped>

</style>
