<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <!-- Card header -->
        <card-header :title="$router.currentRoute.meta.title" />
        <!-- Card body -->
        <form role="form" @submit.prevent="create">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div v-if="serverError" class="alert alert-danger">
                  <strong>{{ $t('labels.error') }} : </strong> {{ serverError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="image">{{ $t(`fields.image`)}}</label>
                  <files-image v-model="image" :error="fieldErrors.profilePicture"/>
                  <span v-if="fieldErrors.profilePicture" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.profilePicture }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="identity">{{ $t(`${transPrefix}.fields.identity`)}}</label>
                  <files-image id="identity" v-model="identity" :error="fieldErrors.identity"/>
                  <span v-if="fieldErrors.identity" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.identity }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="driving_license">{{ $t(`${transPrefix}.fields.driving_license`)}}</label>
                  <files-image id="driving_license" v-model="driving_license" :error="fieldErrors.drivingLicense"/>
                  <span v-if="fieldErrors.drivingLicense" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.drivingLicense }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="form-group">
                  <label for="registration_license">{{ $t(`${transPrefix}.fields.registration_license`)}}</label>
                  <files-image id="registration_license" v-model="registration_license" :thumbnailImage="registration_license.thumbnail" :error="fieldErrors.registrationLicense"/>
                  <span v-if="fieldErrors.registrationLicense" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.registrationLicense }}</strong>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="name">{{ $t(`fields.name`)}}</label>
                  <input  v-model="name"
                          id="name"
                          type="text"
                          class="form-control"
                          name="name"
                          v-bind:class="{'is-invalid':fieldErrors.name}"
                  >
                  <span v-if="fieldErrors.name" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.name }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="email">{{ $t(`fields.email`)}}</label>
                  <input  v-model="email"
                          id="email"
                          type="text"
                          class="form-control"
                          name="email"
                          v-bind:class="{'is-invalid':fieldErrors.email}"
                          @keydown.space.prevent
                          readonly
                  >
                  <span v-if="fieldErrors.email" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.email }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="country_id">{{ $t(`fields.country`)}}</label>
                  <select-countries v-model="country_id" :action="countries_list_action" :error="fieldErrors.countryId"/>
                  <span v-if="fieldErrors.countryId" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.countryId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="default_city_id">{{ $t(`${transPrefix}.fields.default_city`)}}</label>
                  <select-cities v-model="default_city_id" :country_id="country_id" :action="cities_list_action" id="default_city_id" :disabled="!country_id" :error="fieldErrors.defaultCityId"/>
                  <span v-if="fieldErrors.defaultCityId" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.defaultCityId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="mobile_number">{{ $t(`fields.mobile_number`)}}</label>
                  <input  v-model="mobile_number"
                          id="mobile_number"
                          type="text"
                          class="form-control"
                          name="mobile_number"
                          v-bind:class="{'is-invalid':fieldErrors['userMobile.mobileNumber']}"
                  >
                  <span v-if="fieldErrors['userMobile.mobileNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['userMobile.mobileNumber'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="date_of_birth">{{ $t(`fields.date_of_birth`)}}</label>
                  <input  v-model="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          class="form-control"
                          name="date_of_birth"
                          v-bind:class="{'is-invalid':fieldErrors['details.dateOfBirth']}"
                  >
                  <span v-if="fieldErrors['details.dateOfBirth']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.dateOfBirth'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="nationality" class="d-block">{{ $t(`fields.nationality`)}}</label>
                  <vue-country-code id="nationality" class="w-25"
                                    @onSelect="onSelectNationality"
                                    :defaultCountry="nationality"
                                    :dropdownOptions="{ disabledDialCode: true }"
                  />
                  <input  :value="nationality_label"
                          type="text"
                          class="form-control w-75 d-inline-block"
                          name="nationality"
                          v-bind:class="{'is-invalid':fieldErrors['details.nationality']}"
                          disabled
                  >
                  <span v-if="fieldErrors['details.nationality']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.nationality'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_number">{{ $t(`${transPrefix}.fields.id_number`)}}</label>
                  <input  v-model="id_number"
                          id="id_number"
                          type="text"
                          class="form-control"
                          name="id_number"
                          v-bind:class="{'is-invalid':fieldErrors.idNumber}"
                          @keydown.space.prevent
                  >
                  <span v-if="fieldErrors.idNumber" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.idNumber }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="id_type">{{ $t(`${transPrefix}.fields.id_type`)}}</label>
                  <select-captain-id-type v-model="id_type" :error="fieldErrors.idType"/>
                  <span v-if="fieldErrors.idType" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.idType }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="password">{{ $t(`fields.password`)}}</label>
                  <input  v-model="password"
                          id="password"
                          type="password"
                          class="form-control"
                          name="password"
                          v-bind:class="{'is-invalid':fieldErrors.password}"
                  >
                  <span v-if="fieldErrors.password" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors.password }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="iban_name">{{ $t(`${transPrefix}.fields.iban_name`)}}</label>
                  <input  v-model="iban_name"
                          id="iban_name"
                          type="text"
                          class="form-control"
                          name="iban_name"
                          v-bind:class="{'is-invalid':fieldErrors['iban.name']}"
                  >
                  <span v-if="fieldErrors['iban.name']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['iban.name'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="iban_number">{{ $t(`${transPrefix}.fields.iban_number`)}}</label>
                  <input  v-model="iban_number"
                          id="iban_number"
                          type="text"
                          class="form-control"
                          name="iban_number"
                          v-bind:class="{'is-invalid':fieldErrors['iban.iban']}"
                  >
                  <span v-if="fieldErrors['iban.iban']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['iban.iban'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="iban_bank_id">{{ $t(`${transPrefix}.fields.iban_bank`)}}</label>
                  <select-banks v-model="iban_bank_id" id="iban_bank_id" :action="banks_list_action" :error="fieldErrors['iban.bankId']"/>
                  <span v-if="fieldErrors['iban.bankId']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['iban.bankId'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="fintech_type">{{ $t(`${transPrefix}.fields.fintech_type`)}}</label>
                  <select-fintech-type v-model="fintech_type" :error="fieldErrors['fintechAccount.accountType']"/>
                  <span v-if="fieldErrors['fintechAccount.accountType']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['fintechAccount.accountType'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="fintech_id">{{ $t(`${transPrefix}.fields.fintech_id`)}}</label>
                  <input  v-model="fintech_id"
                          id="fintech_id"
                          type="text"
                          class="form-control"
                          name="fintech_id"
                          v-bind:class="{'is-invalid':fieldErrors['fintechAccount.accountId']}"
                  >
                  <span v-if="fieldErrors['fintechAccount.accountId']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['fintechAccount.accountId'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="platform_id">{{ $t(`fields.platform`)}}</label>
                  <select-platforms v-model="platform_id" :error="fieldErrors.platformId" />
                  <span v-if="fieldErrors.platformId" class="invalid-feedback d-block" role="alert">
                      <strong>{{ fieldErrors.platformId }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_plate_number">{{ $t(`${transPrefix}.fields.vehicle_plate_number`)}}</label>
                  <input  v-model="vehicle_plate_number"
                          id="vehicle_plate_number"
                          type="text"
                          class="form-control"
                          name="vehicle_plate_number"
                          v-bind:class="{'is-invalid':fieldErrors['details.vehiclePlateNumber']}"
                  >
                  <span v-if="fieldErrors['details.vehiclePlateNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.vehiclePlateNumber'] }}</strong>
                  </span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group ">
                  <label for="vehicle_sequence_number">{{ $t(`${transPrefix}.fields.vehicle_sequence_number`)}}</label>
                  <input  v-model="vehicle_sequence_number"
                          id="vehicle_sequence_number"
                          type="text"
                          class="form-control"
                          name="vehicle_sequence_number"
                          v-bind:class="{'is-invalid':fieldErrors['details.vehicleSequenceNumber']}"
                  >
                  <span v-if="fieldErrors['details.vehicleSequenceNumber']" class="invalid-feedback" role="alert">
                      <strong>{{ fieldErrors['details.vehicleSequenceNumber'] }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Card footer -->
          <div class="card-footer">
            <div class="row">
              <div class="col text-right">
                <router-link :to="{ name: `${routePrefix}.index`}"  class="btn btn-neutral btn-sm">
                  <i class="fa fa-arrow-left"></i>
                </router-link>
                <button type="submit" class="btn btn-primary btn-sm" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                  <i class="fa fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import { CAPTAINS_CREATE as CRUD_ACTION_CREATE } from 'actions/suppliers_portal/captains';
  import { COUNTRIES_PUBLIC_LIST } from 'actions/countries';
  import { CITIES_PUBLIC_LIST } from 'actions/cities';
  import { BANKS_PUBLIC_LIST } from 'actions/banks';

  const CAPTAINS_CODE="captains";
  const CRUD_CODE=`suppliers_portal.${CAPTAINS_CODE}`;
  export default {
    name: "SuppliersPortalCaptainCreate",
    data(){
      return {
        routePrefix: CRUD_CODE,
        transPrefix: CAPTAINS_CODE,
        image:{
          file:'',
          thumbnail:'',
        },
        driving_license:{
          file:'',
          thumbnail:'',
        },
        registration_license:{
          file:'',
          thumbnail:'',
        },
        identity:{
          file:'',
          thumbnail:'',
        },
        name: '',
        password: '',
        mobile_number: '',
        nationality: '',
        nationality_label: '',
        date_of_birth: '',
        iban_name:'',
        iban_number:'',
        vehicle_plate_number:'',
        vehicle_sequence_number:'',
        status:'',
        iban_bank_id:'',
        id_number:'',
        id_type:'',
        default_city_id:'',
        country_id: "",
        fintech_type: "",
        fintech_id: "",
        platform_id: "",
        serverError: '',
        fieldErrors: {},
        loading: false,
        banks_list_action:BANKS_PUBLIC_LIST,
        countries_list_action:COUNTRIES_PUBLIC_LIST,
        cities_list_action:CITIES_PUBLIC_LIST,
      }
    },
    computed:{
      email:{
        get: function(){
          return `${this.id_number}@samurai.delivery`
        }
      }
    },
    methods: {
      prepareIbanRequestData(){
        let iban={};
        if(this.iban_name || this.iban_bank_id || this.iban_number){
          iban= {
            "iban.name": this.sanitizeField('iban_name'),
            "iban.iban": this.sanitizeField('iban_number'),
            "iban.bankId": this.sanitizeField('iban_bank_id'),
          };
        }

        return iban;
      },
      prepareFintechRequestData() {
        let fintechAccount={};
        if (this.fintech_type && this.fintech_id) {
            fintechAccount= {
              "fintechAccount.accountType": this.sanitizeField('fintech_type'),
              "fintechAccount.accountId" : this.sanitizeField('fintech_id')
            }
        }
        return fintechAccount;
      },
      prepareDetailsRequestData() {
        let details={};
        if(this.date_of_birth || this.nationality){
          details= {
            "details.nationality": this.sanitizeField('nationality'),
            "details.dateOfBirth": this.sanitizeField('date_of_birth'),
            "details.vehiclePlateNumber": this.sanitizeField('vehicle_plate_number'),
            "details.vehicleSequenceNumber": this.sanitizeField('vehicle_sequence_number'),
          };
        }
        return details;
      },
      prepareRequestData(){ 
        return {
          email: this.sanitizeField('email'),
          name: this.sanitizeField('name'),
          countryId: this.sanitizeField('country_id'),
          password: this.sanitizeField('password'),
          idType: this.sanitizeField('id_type'),
          idNumber: this.sanitizeField('id_number'),
          platformId: this.sanitizeField('platform_id'),
          defaultCityId: this.sanitizeField('default_city_id'),
          "userMobile.mobileNumber":this.sanitizeField('mobile_number'),
          ...this.prepareIbanRequestData(),
          ...this.prepareFile('image',"profilePicture"),
          ...this.prepareFile('driving_license',"drivingLicense"),
          ...this.prepareFile('registration_license',"registrationLicense"),
          ...this.prepareFile('identity',"identity"),
          ...this.prepareFintechRequestData(),
          ...this.prepareDetailsRequestData(),
        }
      },
      create() {
        this.loading = true
        this.$store.dispatch(CRUD_ACTION_CREATE, {
          ...this.prepareRequestData(),
        })
        .then(() => {
          swal({
            icon: 'success',
            text: this.$i18n.t("messages.create_successfully"),
            buttons:false,
            timer: 5000
          });
          this.$router.push({ name: `${this.routePrefix}.index` })
        })
        .catch(error => {
          this.loading = false
          this.fieldErrors={};
          this.serverError='';

          if(error.response){
            let responseData=error.response.data;

            if(responseData.errors){
              this.fieldErrors=responseData.errors;
            }else{
              this.serverError = 'Invalid Request.'
            }
          }else{
            this.serverError = this.$i18n.t("messages.no_internet_connection");
          }

          if(this.fieldErrors.error){
            this.serverError = this.fieldErrors.error;
          }
        })
      },
      onSelectNationality({name, iso2, dialCode}) {
        this.nationality=iso2.toLowerCase();
        this.nationality_label=`${name} (${iso2})`;
      },
    }
  };
</script>
